@import "../static/styles/color.scss";

input::placeholder,
textarea::placeholder {
  color: $color-greyscale40;
}

.appSkeleton {
  padding-left: 0;
  padding-top: 60px;
}

.appSkeleton-appBar {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);
}

.appSkeleton-sideBar {
  display: none;
}

.appSkeleton-appBarSkeleton {
  position: absolute;
  top: 0;
  right: 0;
}

.appSkeleton-appBarSkeleton > .skeleton {
  vertical-align: top;
  background-color: $color-greyscale30;
  display: none;
}

.appSkeleton-appBarSkeleton > .skeleton:nth-child(1) {
  width: 40px;
  height: 40px;
  margin: 10px 0;
  border-radius: 50%;
}

.appSkeleton-appBarSkeleton > .skeleton:nth-child(2) {
  width: 120px;
  height: 18px;
  margin: 21px 0 21px 8px;
  border-radius: 8px;
}

.appSkeleton-appBarSkeleton > .skeleton:nth-child(3) {
  width: 50px;
  height: 18px;
  margin: 21px 24px 21px 48px;
  border-radius: 8px;
}

.appSkeleton-appBarDrawerSkeleton {
  height: 32px;
  width: 32px;
  border-radius: 6px;
  position: absolute;
  top: 14px;
  left: 16px;
  background-color: $color-greyscale30;
}

.appSkeleton-sideBarSkeleton {
  padding-top: 36px;
}

.appSkeleton-sideBarSkeleton > .skeleton {
  width: 100%;
  height: 18px;
  margin-bottom: 24px;
  border-radius: 6px;
  background-color: $color-greyscale20;
}

.appSkeleton-sideBarSkeleton > .skeleton:nth-child(4),
.appSkeleton-sideBarSkeleton > .skeleton:nth-child(7) {
  margin-top: 48px;
}

@media screen and (min-width: 800px) {
  .appSkeleton {
    padding-left: 300px;
  }

  .appSkeleton--contributor {
    padding-left: 0px;
  }

  .appSkeleton-appBarSkeleton > .skeleton {
    display: inline-block;
  }

  .appSkeleton-appBarDrawerSkeleton {
    display: none;
  }

  .appSkeleton-sideBar {
    display: block;
  }
}
