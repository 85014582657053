@import "./color.scss";

.u-border0 {
  border: 0 !important;
  border: none !important;
  -webkit-appearance: none !important;
}

.u-border1 {
  border: 1px solid !important;
}

.u-border2 {
  border: 2px solid !important;
}

.u-border3 {
  border: 3px solid !important;
}

.u-border4 {
  border: 4px solid !important;
}

.u-borderTop1 {
  border-top: 1px solid !important;
}

.u-borderBottom1 {
  border-bottom: 1px solid !important;
}

.u-borderRight1 {
  border-right: 1px solid !important;
}

.u-borderLeft1 {
  border-left: 1px solid !important;
}

.u-borderRadius0 {
  -webkit-border-radius: 0 !important;
  -moz-border-radius: 0 !important;
  border-radius: 0 !important;
}

.u-borderRadius2 {
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  border-radius: 2px !important;
}

.u-borderRadius3 {
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  border-radius: 3px !important;
}

.u-borderRadius4 {
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  border-radius: 4px !important;
}

.u-borderRadius6 {
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  border-radius: 6px !important;
}

.u-borderRadius8 {
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  border-radius: 8px !important;
}

.u-borderRadius20 {
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  border-radius: 20px !important;
}

.u-borderRadius24 {
  -webkit-border-radius: 24px !important;
  -moz-border-radius: 24px !important;
  border-radius: 24px !important;
}

.u-borderRadius100 {
  -webkit-border-radius: 100px !important;
  -moz-border-radius: 100px !important;
  border-radius: 100px !important;
}

.u-borderRadius50Percent {
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}

.u-borderColorGreyscaleWhite {
  border-color: $color-greyscale-white !important;
}

.u-borderColorGreyscale10 {
  border-color: $color-greyscale10 !important;
}

.u-borderColorGreyscale20 {
  border-color: $color-greyscale20 !important;
}

.u-borderColorGreyscale30 {
  border-color: $color-greyscale30 !important;
}

.u-borderColorGreyscale40 {
  border-color: $color-greyscale40 !important;
}

.u-borderColorRobinegg80 {
  border-color: $color-robinegg80 !important;
}

.u-borderColorRobinegg100 {
  border-color: $color-robinegg100 !important;
}

.u-borderColorYellow100 {
  border-color: $color-yellow100 !important;
}

.u-borderColorError {
  border-color: $color-error !important;
}

.u-outline0 {
  outline: 0;
}