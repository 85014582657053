.toast-bar {
  min-height: 48px;
  width: 90%;
  transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  transform: translateY(200%);
  -o-transform: translateY(200%);
  -ms-transform: translateY(200%);
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
  max-width: 312px;
}

.toast-bar.is-hiding {
  transform: translateY(200%);
  -o-transform: translateY(200%);
  -ms-transform: translateY(200%);
  -webkit-transform: translateY(200%);
  -moz-transform: translateY(200%);
}

.toast-bar.is-showing {
  transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
}

@media screen and (max-width: 800px) {
  .toast-bar {
    max-width: 500px;
  }
}