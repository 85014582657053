@import "../../static/styles/color.scss";
@import "../../static/styles/font-weight.scss";

.AppLayout {
  min-height: 100vh;
}

.AppLayout-appBar {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.05);
}

.AppLayout-drawerAuth {
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.05);
}

.AppLayout-auth {
  display: none;
}

.AppLayout-drawer.is-drawer-open > .AppLayout-drawerOverlay {
  display: block;
}

.AppLayout-drawerOverlay {
  display: none;
  background-color: rgba(0, 0, 0, .4);
}

.AppLayout-drawerMenu {
  position: fixed;
  width: 80%;
  transform: translateX(-100%);
  transition: all .2s ease-in-out;
  box-shadow: 2px 0 8px 2px rgba(0, 0, 0, 0.15) !important;
}

.AppLayout-drawer.is-drawer-open > .AppLayout-drawerMenu {
  transform: translateX(0);
}

.AppLayout-navSection > a {
  color: $color-greyscale60;
  position: relative;
}

.AppLayout-navSection > a.is-active,
.AppLayout-navSection > a:hover {
  font-weight: $font-medium;
  color: $color-greyscale-black;
}

.AppLayout-navSection > a:hover svg {
  color: $color-greyscale-black;
}

.AppLayout-navSection > a.is-active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 28px;
  margin: 4px 0;
  background-color: $color-peach100;
}

.AppLayout-navSection .navSection-chevronIcon.is-showMenu {
  transform: rotate(180deg);
}

.AppLayout-navSection .navSection-itemRedDot {
  display: inline-block;
  margin-left: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: $color-error;
  position: relative;
  top: -6px
}

.AppLayout-navSection .navSection-panelMenu {
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.logo {
  background-image: url('../../static/icons/endeus_logo.svg');
  background-size: contain;
}

@media only screen and (min-width: 800px) {
  .AppLayout-auth {
    display: block;
  }

  .AppLayout-drawer {
    position: fixed;
    width: 300px;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);    
  }

  .AppLayout-drawer.is-drawer-open > .AppLayout-drawerOverlay {
    display: none !important;
  }

  .AppLayout-drawer.is-drawer-open > .AppLayout-drawerMenu,
  .AppLayout-drawerMenu {
    position: relative !important;
    width: 100%;
    transform: translateX(0);
    box-shadow: none !important;
  }

  .AppLayout-drawerAuth {
    display: none !important;
  }

  .AppLayout-content {
    padding-left: 300px;
  }
}

@media only screen and (min-width: 1500px) {
  .AppLayout-content {
    max-width: 1440px;
    margin: 0 auto;
  }
}
