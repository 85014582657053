@import "./color.scss";

.u-backgroundSizeCover {
  background-size: cover !important;
}

.u-backgroundSizeContain {
  background-size: contain !important;
}

.u-backgroundNoRepeat {
  background-repeat: no-repeat !important;
}

.u-backgroundPositionCenter {
  background-position: center !important;
}

.u-backgroundColorTransparent {
  background-color: transparent !important;
}

.u-backgroundColorWhite {
  background-color: $color-greyscale-white !important;
}

.u-backgroundColorGreyscaleBlack {
  background-color: $color-greyscale-black !important;
}

.u-backgroundOverlayWhite {
  background-color: rgba(255, 255, 255, .7) !important;
}

.u-backgroundOverlayBlack {
  background-color: rgba(0, 0, 0, .6) !important;
}

.u-backgroundColorGreyscale10 {
  background-color: $color-greyscale10 !important;
}

.u-hover-backgroundColorGreyscale10:hover {
  background-color: $color-greyscale10 !important;
}

.u-backgroundColorGreyscale20 {
  background-color: $color-greyscale20 !important;
}

.u-backgroundColorGreyscale30 {
  background-color: $color-greyscale30 !important;
}

.u-backgroundColorGreyscale40 {
  background-color: $color-greyscale40 !important;
}

.u-backgroundColorGreyscale50 {
  background-color: $color-greyscale50 !important;
}

.u-backgroundColorPeach40 {
  background-color: $color-peach40 !important;
}

.u-backgroundColorPeach60 {
  background-color: $color-peach60 !important;
}

.u-backgroundColorPeach100 {
  background-color: $color-peach100 !important;
}

.u-backgroundColorRobinEgg100 {
  background-color: $color-robinegg100 !important;
}

.u-backgroundColorGreen100 {
  background-color: $color-green100 !important;
}

.u-backgroundColorYellow20 {
  background-color: $color-yellow20 !important;
}

.u-backgroundColorYellow100 {
  background-color: $color-yellow100 !important;
}