@import '../../static/styles/color.scss';

.icon {
  fill: currentColor;
  display: block;
}

.icon--dark {
  color: $color-greyscale-black;
}

.icon--robinegg {
  color: $color-robinegg100;
}

.icon--robinegg80 {
  color: $color-robinegg80;
}

.icon--grey30 {
  color: $color-greyscale30;
}

.icon--greyBlue {
  color: $color-greyscale40;
}

.icon--grey50 {
  color: $color-greyscale50;
}

.icon--peach {
  color: $color-peach100;
}

.icon--white {
  color: $color-greyscale-white;
}
