.u-clearfix:after {
  clear: both;
}

.u-clearfix:after,
.u-clearfix:before {
  display: table;
  content: ' ';
}

.u-displayNone {
  display: none;
}

.u-floatRight {
  float: right !important;
}

.u-floatLeft {
  float: left !important;
}

.u-relative {
  position: relative !important;
}

.u-absolute {
  position: absolute !important;
}

.u-fixed {
  position: fixed !important;
}

.u-block {
  display: block !important;
}

.u-inlineBlock {
  display: inline-block !important;
}

.u-listStyleNone {
  list-style: none !important;
  list-style-type: none !important;
}

.u-flex0 {
  -webkit-box-flex: 0 !important;
  -webkit-flex: 0 0 auto !important;
  -ms-flex: 0 0 auto !important;
  flex: 0 0 auto !important;
}

.u-flex1 {
  -webkit-box-flex: 1 !important;
  -webkit-flex: 1 1 auto !important;
  -ms-flex: 1 1 auto !important;
  flex: 1 1 auto !important;
}

.u-flex {
  display: -webkit-box !important;
  display: -moz-box !important;
  display: -ms-flexbox !important;
  display: -webkit-flex !important;
  display: flex !important;
}

.u-flexWrap {
  flex-wrap: wrap;
}

.u-inlineFlex {
  display: -webkit-inline-flex !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.u-flexGrow1 {
  flex-grow: 1 !important;
}

.u-floatLeft {
  float: left;
}

.u-floatRight {
  float: right;
}

.u-absolute {
  position: absolute !important;
}

.u-relative {
  position: relative !important;
}

.u-flexJustifyContentCenter {
  justify-content: center !important;
}

.u-flexJustifyContentSpaceBetween {
  justify-content: space-between !important;
}

.u-flexJustifyContentEnd {
  justify-content: flex-end !important;
}

.u-alignSelfStart {
  align-self: flex-start !important;
}

.u-alignSelfCenter {
  align-self: center !important;
}

.u-flexAlignItemsStretch {
  -webkit-box-align: stretch !important;
  -webkit-align-items: stretch !important;
  -ms-flex-align: stretch !important;
  align-items: stretch !important;
}

.u-flexAlignItemsCenter {
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.u-flexAlignItemsStart {
  -webkit-box-align: start !important;
  -webkit-align-items: start !important;
  -ms-flex-align: start !important;
  align-items: start !important;
}

.u-flexAlignItemsBaseline {
  -webkit-box-align: baseline !important;
  -webkit-align-items: baseline !important;
  -ms-flex-align: baseline !important;
  align-items: baseline !important;
}

.u-flexDirectionColumn {
  -webkit-flex-direction: column;
  flex-direction: column;
}

.u-flexBasis50Percent {
  flex-basis: 50% !important;
}

.u-flexBasis70Percent {
  flex-basis: 70% !important;
}

.u-whiteSpaceNoWrap {
  white-space: nowrap !important;
}

.u-whiteSpaceNormal {
  white-space: normal !important;
}

.u-flexBasis120 {
  flex-basis: 120px !important;
}

.u-top0 {
  top: 0 !important;
}

.u-top3 {
  top: 3px !important;
}

.u-top4 {
  top: 4px !important;
}

.u-top6 {
  top: 4px !important;
}

.u-top7 {
  top: 7px !important;
}

.u-top8 {
  top: 8px !important;
}

.u-top9 {
  top: 9px !important;
}

.u-top10 {
  top: 10px !important;
}

.u-top12 {
  top: 12px !important;
}

.u-top16 {
  top: 16px !important;
}

.u-top21 {
  top: 21px !important;
}

.u-top24 {
  top: 24px !important;
}

.u-top37 {
  top: 37px !important;
}

.u-top75 {
  top: 75px !important;
}

.u-top10Percent {
  top: 10% !important;
}

.u-top50Percent {
  top: 50% !important;
}

.u-topMin124 {
  top: -124px !important;
}

.u-left0 {
  left: 0 !important;
}

.u-left8 {
  left: 8px !important;
}

.u-left48 {
  left: 48px !important;
}

.u-left12 {
  left: 12px !important;
}

.u-left16 {
  left: 16px !important;
}

.u-left36 {
  left: 36px !important;
}

.u-left40 {
  left: 40px !important;
}

.u-left48 {
  left: 48px !important;
}

.u-right0 {
  right: 0 !important;
}

.u-right4 {
  right: 4px !important;
}

.u-right6 {
  right: 6px !important;
}

.u-right8 {
  right: 8px !important;
}

.u-right12 {
  right: 12px !important;
}

.u-right16 {
  right: 16px !important;
}

.u-right24 {
  right: 24px !important;
}

.u-bottom0 {
  bottom: 0 !important;
}

.u-bottom8 {
  bottom: 8px !important;
}

.u-bottom12 {
  bottom: 12px !important;
}

.u-bottom16 {
  bottom: 16px !important;
}

.u-bottom24 {
  bottom: 24px !important;
}

@media screen and (min-width: 800px) {
  .u-md-flex {
    display: -webkit-box !important;
    display: -moz-box !important;
    display: -ms-flexbox !important;
    display: -webkit-flex !important;
    display: flex !important;
  }

  .u-md-flex0 {
    -webkit-box-flex: 0 !important;
    -webkit-flex: 0 0 auto !important;
    -ms-flex: 0 0 auto !important;
    flex: 0 0 auto !important;
  }

  .u-md-flex1 {
    -webkit-box-flex: 1 !important;
    -webkit-flex: 1 1 auto !important;
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }

  .u-md-flexJustifyContentSpaceBetween {
    justify-content: space-between !important;
  }

  .u-md-flexAlignItemsCenter {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .u-md-flexBasis50Percent {
    flex-basis: 50% !important;
  }

  .u-md-block {
    display: block !important;
  }

  .u-md-initial {
    display: initial !important;
  }
}
