$zIndex0: 0;
$zIndex1: 100;
$zIndex2: 200;
$zIndex3: 300;
$zIndex4: 400;
$zIndex5: 500;
$zIndex6: 600;
$zIndex7: 700;
$zIndex8: 800;
$zIndex9: 900;

.u-zIndex0 {
  z-index: $zIndex0;
}

.u-zIndex1 {
  z-index: $zIndex1;
}

.u-zIndex2 {
  z-index: $zIndex2;
}

.u-zIndex3 {
  z-index: $zIndex3;
}

.u-zIndex4 {
  z-index: $zIndex4;
}

.u-zIndex5 {
  z-index: $zIndex5;
}

.u-zIndex6 {
  z-index: $zIndex6;
}

.u-zIndex7 {
  z-index: $zIndex7;
}

.u-zIndex8 {
  z-index: $zIndex8;
}

.u-zIndex9 {
  z-index: $zIndex9;
}