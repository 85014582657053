.u-width0 {
  width: 0 !important;
}

.u-width4 {
  width: 4px !important;
}

.u-width6 {
  width: 6px !important;
}

.u-width8 {
  width: 8px !important;
}

.u-width10 {
  width: 10px !important;
}

.u-width12 {
  width: 12px !important;
}

.u-width13 {
  width: 13px !important;
}

.u-width14 {
  width: 14px !important;
}

.u-width16 {
  width: 16px !important;
}

.u-width18 {
  width: 18px !important;
}

.u-width20 {
  width: 20px !important;
}

.u-width23 {
  width: 23px !important;
}

.u-width24 {
  width: 24px !important;
}

.u-width28 {
  width: 28px !important;
}

.u-width32 {
  width: 32px !important;
}

.u-width36 {
  width: 36px !important;
}

.u-width40 {
  width: 40px !important;
}

.u-width44 {
  width: 44px !important;
}

.u-width48 {
  width: 48px !important;
}

.u-width55 {
  width: 55px !important;
}

.u-width56 {
  width: 56px !important;
}

.u-width59 {
  width: 59px !important;
}

.u-width60 {
  width: 60px !important;
}

.u-width64 {
  width: 64px !important;
}

.u-width70 {
  width: 70px !important;
}

.u-width80 {
  width: 80px !important;
}

.u-width84 {
  width: 84px !important;
}

.u-width90 {
  width: 90px !important;
}

.u-width100 {
  width: 100px !important;
}

.u-width110 {
  width: 110px !important;
}

.u-width120 {
  width: 120px !important;
}

.u-width121 {
  width: 121px !important;
}

.u-width126 {
  width: 126px !important;
}

.u-width128 {
  width: 128px !important;
}

.u-width130 {
  width: 130px !important;
}

.u-width140 {
  width: 140px !important;
}

.u-width142 {
  width: 142px !important;
}

.u-width150 {
  width: 150px !important;
}

.u-width160 {
  width: 160px !important;
}

.u-width180 {
  width: 180px !important;
}

.u-width182 {
  width: 182px !important;
}

.u-width192 {
  width: 192px !important;
}

.u-width200 {
  width: 200px !important;
}

.u-width232 {
  width: 232px !important;
}

.u-width234 {
  width: 234px !important;
}

.u-width240 {
  width: 240px !important;
}

.u-width300 {
  width: 300px !important;
}

.u-width320 {
  width: 320px !important;
}

.u-width360 {
  width: 360px !important;
}

.u-width400 {
  width: 400px !important;
}

.u-width700 {
  width: 700px !important;
}

.u-width5Percent {
  width: 5% !important;
}

.u-width10Percent {
  width: 10% !important;
}

.u-width15Percent {
  width: 15% !important;
}

.u-width20Percent {
  width: 20% !important;
}

.u-width25Percent {
  width: 25% !important;
}

.u-width40Percent {
  width: 40% !important;
}

.u-width47HalfPercent {
  width: 47.5% !important;
}

.u-width65Percent {
  width: 65% !important;
}

.u-width80Percent {
  width: 80% !important;
}

.u-minWidth784 {
  min-width: 784px !important;
}

.u-minWidth1000 {
  min-width: 1000px !important;
}

.u-maxWidth100 {
  max-width: 100px !important;
}

.u-maxWidth120 {
  max-width: 120px !important;
}

.u-maxWidth240 {
  max-width: 240px !important;
}

.u-maxWidth300 {
  max-width: 300px !important;
}

.u-maxWidth360 {
  max-width: 360px !important;
}

.u-maxWidth400 {
  max-width: 400px !important;
}

.u-maxWidth560 {
  max-width: 560px !important;
}

.u-maxWidth700 {
  max-width: 700px !important;
}

.u-maxWidth760 {
  max-width: 760px !important;
}

.u-maxWidthFull {
  max-width: 100% !important;
}

.u-width100vw {
  width: 100vw !important;
}

.u-width30Percent {
  width: 30% !important;
}

.u-width47p5Percent {
  width: 47.5% !important;
}

.u-height0 {
  height: 0 !important;
}

.u-height4 {
  height: 4px !important;
}

.u-height8 {
  height: 8px !important;
}

.u-height10 {
  height: 10px !important;
}

.u-height12 {
  height: 12px !important;
}

.u-height14 {
  height: 14px !important;
}

.u-height15 {
  height: 15px !important;
}

.u-height16 {
  height: 16px !important;
}

.u-height18 {
  height: 18px !important;
}

.u-height20 {
  height: 20px !important;
}

.u-height24 {
  height: 24px !important;
}

.u-height27 {
  height: 27px !important;
}

.u-height30 {
  height: 30px !important;
}

.u-height32 {
  height: 32px !important;
}

.u-height34 {
  height: 34px !important;
}

.u-height36 {
  height: 36px !important;
}

.u-height38 {
  height: 38px !important;
}

.u-height40 {
  height: 40px !important;
}

.u-height42 {
  height: 42px !important;
}

.u-height44 {
  height: 44px !important;
}

.u-height46 {
  height: 46px !important;
}

.u-height48 {
  height: 48px !important;
}

.u-height49 {
  height: 49px !important;
}

.u-height50 {
  height: 50px !important;
}

.u-height52 {
  height: 52px !important;
}

.u-height55 {
  height: 55px !important;
}

.u-height56 {
  height: 56px !important;
}

.u-height60 {
  height: 60px !important;
}

.u-height62 {
  height: 62px !important;
}

.u-height64 {
  height: 64px !important;
}

.u-height70 {
  height: 70px !important;
}

.u-height75 {
  height: 75px !important;
}

.u-height80 {
  height: 80px !important;
}

.u-height82 {
  height: 82px !important;
}

.u-height83 {
  height: 83px !important;
}

.u-height90 {
  height: 90px !important;
}

.u-height92 {
  height: 92px !important;
}

.u-height94 {
  height: 94px !important;
}

.u-height100 {
  height: 100px !important;
}

.u-height113 {
  height: 113px !important;
}

.u-height120 {
  height: 120px !important;
}

.u-height125 {
  height: 125px !important;
}

.u-height130 {
  height: 125px !important;
}

.u-height148 {
  height: 148px !important;
}

.u-height160 {
  height: 160px !important;
}

.u-height180 {
  height: 180px !important;
}

.u-height270 {
  height: 270px !important;
}

.u-height273 {
  height: 273px !important;
}

.u-height50vh {
  height: 50vh !important;
}

.u-height100vh {
  height: 100vh !important;
}

.u-minHeight48 {
  min-height: 48px !important;
}

.u-minHeight75 {
  min-height: 75px !important;
}

.u-minHeight160 {
  min-height: 160px !important;
}

.u-minHeight100vh {
  min-height: 100vh !important;
}

.u-maxHeight34 {
  max-height: 34px !important;
}

.u-maxHeight40 {
  max-height: 40px !important;
}

.u-maxHeight48 {
  max-height: 48px !important;
}

.u-maxHeight54 {
  max-height: 54px !important;
}

.u-maxHeight80Percent {
  max-height: 80% !important;
}

.u-sizeFull {
  width: 100% !important;
  height: 100% !important;
}

.u-size12 {
  width: 12px !important;
  height: 12px !important;
}

.u-size14 {
  width: 14px !important;
  height: 14px !important;
}

.u-size16 {
  width: 16px !important;
  height: 16px !important;
}

.u-size18 {
  width: 18px !important;
  height: 18px !important;
}

.u-size20 {
  width: 20px !important;
  height: 20px !important;
}

.u-size24 {
  width: 24px !important;
  height: 24px !important;
}

.u-size26 {
  width: 26px !important;
  height: 26px !important;
}

.u-size30 {
  width: 30px !important;
  height: 30px !important;
}

.u-size32 {
  width: 32px !important;
  height: 32px !important;
}

.u-size36 {
  width: 36px !important;
  height: 36px !important;
}

.u-size40 {
  width: 40px !important;
  height: 40px !important;
}

.u-size48 {
  width: 48px !important;
  height: 48px !important;
}

.u-size50 {
  width: 50px !important;
  height: 50px !important;
}

.u-size60 {
  width: 60px !important;
  height: 60px !important;
}

.u-size64 {
  width: 64px !important;
  height: 64px !important;
}

.u-size82 {
  width: 82px !important;
  height: 82px !important;
}

.u-size86 {
  width: 86px !important;
  height: 86px !important;
}

.u-size100 {
  width: 100px !important;
  height: 100px !important;
}

.u-size113 {
  width: 113px !important;
  height: 113px !important;
}

.u-size120 {
  width: 120px !important;
  height: 120px !important;
}

.u-size160 {
  width: 160px !important;
  height: 160px !important;
}

.u-fullWidth {
  width: 100% !important;
}

.u-fullHeight {
  height: 100% !important;
}

.u-fitHeight {
  height: fit-content !important;
}

.u-fullSize {
  width: 100% !important;
  height: 100% !important;
}

@media screen and (max-width: 799px) {
  .u-sm-fullWidth {
    width: 100% !important;
  }
}

@media screen and (min-width: 800px) {
  .u-md-width40Percent {
    width: 40% !important;
  }

  .u-md-width47HalfPercent {
    width: 47.5% !important;
  }

  .u-md-width50Percent {
    width: 50% !important;
  }

  .u-md-width60Percent {
    width: 60% !important;
  }

  .u-md-width80Percent {
    width: 80% !important;
  }

  .u-md-width120 {
    width: 120px !important;
  }

  .u-md-width164 {
    width: 164px !important;
  }

  .u-md-width260 {
    width: 260px !important;
  }

  .u-md-width340 {
    width: 340px !important;
  }
}

@media screen and (min-width: 1100px) {
  .u-lg-width22HalfPercent {
    width: 22.5% !important;
  }
}
