@import "../../static/styles/color.scss";

.AppLayout--contributor {
  min-height: 100vh;
  .AppLayout-auth {
    display: block;
  }
}

@media only screen and (min-width: 800px) {
  .AppLayout--contributor {
    .AppLayout-content {
      padding-left: 0px;
      margin: 0 auto;
    }
  }
}

@media only screen and (min-width: 1500px) {
  .AppLayout-content {
    max-width: 1440px;
    margin: 0 auto;
  }
}
