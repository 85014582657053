.u-marginAuto {
  margin: auto !important;
}

.u-margin0 {
  margin: 0px !important;
}

.u-margin4 {
  margin: 4px !important;
}

.u-margin5 {
  margin: 5px !important;
}

.u-margin8 {
  margin: 8px !important;
}

.u-margin10 {
  margin: 10px !important;
}

.u-margin12 {
  margin: 12px !important;
}

.u-margin14 {
  margin: 14px !important;
}

.u-margin16 {
  margin: 16px !important;
}

.u-margin20 {
  margin: 20px !important;
}

.u-margin24 {
  margin: 24px !important;
}

.u-margin32 {
  margin: 32px !important;
}

.u-margin42 {
  margin: 42px !important;
}

.u-marginHorizontal0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.u-marginHorizontal4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.u-marginHorizontal8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.u-marginHorizontal11 {
  margin-left: 11px !important;
  margin-right: 11px !important;
}

.u-marginHorizontal12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.u-marginHorizontal16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.u-marginHorizontal20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.u-marginHorizontal24 {
  margin-left: 24px !important;
  margin-right: 024px !important;
}

.u-marginHorizontal32 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.u-marginHorizontal48 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.u-marginHorizontalAuto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.u-marginVertical0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.u-marginVertical1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.u-marginVertical2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.u-marginVertical4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.u-marginVertical6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.u-marginVertical7 {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.u-marginVertical8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.u-marginVertical9 {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

.u-marginVertical10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.u-marginVertical11 {
  margin-top: 11px !important;
  margin-bottom: 11px !important;
}

.u-marginVertical12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.u-marginVertical13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.u-marginVertical14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.u-marginVertical15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.u-marginVertical16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.u-marginVertical18 {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.u-marginVertical19 {
  margin-top: 19px !important;
  margin-bottom: 19px !important;
}

.u-marginVertical20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.u-marginVertical24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.u-marginVertical32 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.u-marginVertical33 {
  margin-top: 33px !important;
  margin-bottom: 33px !important;
}

.u-marginVertical36 {
  margin-top: 36px !important;
  margin-bottom: 36px !important;
}

.u-marginVerticalAuto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.u-marginLeftAuto {
  margin-left: auto !important;
}

.u-marginLeft0 {
  margin-left: 0px !important;
}

.u-marginLeft2 {
  margin-left: 2px !important;
}

.u-marginLeft4 {
  margin-left: 4px !important;
}

.u-marginLeft8 {
  margin-left: 8px !important;
}

.u-marginLeft10 {
  margin-left: 10px !important;
}

.u-marginLeft12 {
  margin-left: 12px !important;
}

.u-marginLeft14 {
  margin-left: 14px !important;
}

.u-marginLeft16 {
  margin-left: 16px !important;
}

.u-marginLeft24 {
  margin-left: 24px !important;
}

.u-marginLeft32 {
  margin-left: 32px !important;
}

.u-marginLeft36 {
  margin-left: 36px !important;
}

.u-marginTop0 {
  margin-top: 0px !important;
}

.u-marginTop1 {
  margin-top: 1px !important;
}

.u-marginTop2 {
  margin-top: 2px !important;
}

.u-marginTop4 {
  margin-top: 4px !important;
}

.u-marginTop5 {
  margin-top: 5px !important;
}

.u-marginTop6 {
  margin-top: 6px !important;
}

.u-marginTop8 {
  margin-top: 8px !important;
}

.u-marginTop10 {
  margin-top: 10px !important;
}

.u-marginTop12 {
  margin-top: 12px !important;
}

.u-marginTop14 {
  margin-top: 14px !important;
}

.u-marginTop15 {
  margin-top: 15px !important;
}

.u-marginTop16 {
  margin-top: 16px !important;
}

.u-marginTop20 {
  margin-top: 20px !important;
}

.u-marginTop24 {
  margin-top: 24px !important;
}

.u-marginTop28 {
  margin-top: 28px !important;
}

.u-marginTop30 {
  margin-top: 30px !important;
}

.u-marginTop32 {
  margin-top: 32px !important;
}

.u-marginTop36 {
  margin-top: 36px !important;
}

.u-marginTop40 {
  margin-top: 40px !important;
}

.u-marginTop42 {
  margin-top: 42px !important;
}

.u-marginTop48 {
  margin-top: 48px !important;
}

.u-marginTop56 {
  margin-top: 56px !important;
}

.u-marginTop64 {
  margin-top: 64px !important;
}

.u-marginRight0 {
  margin-right: 0px !important;
}

.u-marginRight4 {
  margin-right: 4px !important;
}

.u-marginRight6 {
  margin-right: 6px !important;
}

.u-marginRight8 {
  margin-right: 8px !important;
}

.u-marginRight10 {
  margin-right: 10px !important;
}

.u-marginRight12 {
  margin-right: 12px !important;
}

.u-marginRight16 {
  margin-right: 16px !important;
}

.u-marginRight18 {
  margin-right: 18px !important;
}

.u-marginRight20 {
  margin-right: 20px !important;
}

.u-marginRight24 {
  margin-right: 24px !important;
}

.u-marginRight22 {
  margin-right: 22px !important;
}

.u-marginRight32 {
  margin-right: 32px !important;
}

.u-marginRight42 {
  margin-right: 42px !important;
}

.u-marginRight5Percent {
  margin-right: 5% !important;
}

.u-marginBottom0 {
  margin-bottom: 0px !important;
}

.u-marginBottom4 {
  margin-bottom: 4px !important;
}

.u-marginBottom6 {
  margin-bottom: 6px !important;
}

.u-marginBottom8 {
  margin-bottom: 8px !important;
}

.u-marginBottom9 {
  margin-bottom: 9px !important;
}

.u-marginBottom10 {
  margin-bottom: 10px !important;
}

.u-marginBottom12 {
  margin-bottom: 12px !important;
}

.u-marginBottom16 {
  margin-bottom: 16px !important;
}

.u-marginBottom20 {
  margin-bottom: 20px !important;
}

.u-marginBottom24 {
  margin-bottom: 24px !important;
}

.u-marginBottom30 {
  margin-bottom: 30px !important;
}

.u-marginBottom32 {
  margin-bottom: 32px !important;
}

.u-marginBottom40 {
  margin-bottom: 40px !important;
}

.u-marginBottom48 {
  margin-bottom: 48px !important;
}

.u-marginBottom56 {
  margin-bottom: 56px !important;
}

.u-marginBottom60 {
  margin-bottom: 60px !important;
}

.u-marginBottom64 {
  margin-bottom: 64px !important;
}

.u-marginBottom80 {
  margin-bottom: 80px !important;
}

@media screen and (min-width: 800px) {
  .u-md-marginHorizontal36 {
    margin-left: 36px !important;
    margin-right: 36px !important;
  }

  .u-md-marginTop0 {
    margin-top: 0px !important;
  }

  .u-md-marginTop48 {
    margin-top: 48px !important;
  }

  .u-md-marginBottom48 {
    margin-bottom: 48px !important;
  }

  .u-md-marginRight8 {
    margin-right: 8px !important;
  }

  .u-md-marginRight16 {
    margin-right: 16px !important;
  }

  .u-md-marginLeft16 {
    margin-left: 16px !important;
  }

  .u-md-marginRight42 {
    margin-right: 42px !important;
  }

  .u-md-marginRight3Percent {
    margin-right: 3% !important;
  }

  .u-md-marginRight5Percent {
    margin-right: 5% !important;
  }
}

@media screen and (min-width: 1100px) {
  .u-lg-marginRight2HalfPercent {
    margin-right: 2.5% !important;
  }
}
