$color-black: #000000;

$color-greyscale-black: #231F20;
$color-greyscale-white: #FFFFFF;
$color-greyscale60: #323E48;
$color-greyscale50: #5D687B;
$color-greyscale40: #7D8CA3;
$color-greyscale30: #A7B2C4;
$color-greyscale20: #D2DCE6;
$color-greyscale10: #F0F4FB;

$color-peach100: #FF8D6B;
$color-peach80: #FFA489;
$color-peach60: #FFBBA6;
$color-peach40: #FFD1C4;
$color-peach20: #FFE8E1;

$color-yellow100: #FFCD00;
$color-yellow80: #FFD733;
$color-yellow60: #FFE166;
$color-yellow40: #FFEB99;
$color-yellow20: #FFF5CC;

$color-robinegg100: #45C2B1;
$color-robinegg80: #6ACEC1;
$color-robinegg60: #8FDAD0;
$color-robinegg40: #B5E7E0;
$color-robinegg20: #DAF3EF;

$color-green100: #6ABF4B;
$color-green80: #88CC6F;
$color-green60: #A6D993;
$color-green40: #C3E5B7;
$color-green20: #E1F2DB;

$color-blue100: #00A7E1;
$color-blue80: #33B9E7;
$color-blue60: #66CAED;
$color-blue40: #99DCF3;
$color-blue20: #CCEDF9;

$color-purple100: #8A84D6;
$color-purple80: #A19DDE;
$color-purple60: #B9B5E6;
$color-purple40: #D0CEEF;
$color-purple20: #E8E6F7;

$color-facebook: #3B5998;
$color-twitter: #26A6D1;
$color-whatsapp: #75B73B;
$color-line: #6FC32E;

$color-error:#D7292E;