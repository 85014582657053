@import '../../../static/styles/color.scss';

.spinner {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
}

.spinner > div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 15px;
  height: 15px;
  margin: 1.5px;
  border: 1.5px solid;
  border-radius: 50%;
  animation: spinnerSpin 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.spinner--large {
  width: 80px;
  height: 80px;
}

.spinner--large > div {
  width: 60px;
  height: 60px;
  margin: 10px;
  border: 8px solid;
}

.spinner > div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner > div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner > div:nth-child(3) {
  animation-delay: -0.15s;
}

.spinner--peach > div {
  border-color: $color-peach100 transparent transparent transparent;
}

.spinner--yellow > div {
  border-color: $color-yellow100 transparent transparent transparent;
}

.spinner--robinegg > div {
  border-color: $color-robinegg100 transparent transparent transparent;
}

.spinner--green > div {
  border-color: $color-green100 transparent transparent transparent;
}

.spinner--blue > div {
  border-color: $color-blue100 transparent transparent transparent;
}

.spinner--purple > div {
  border-color: $color-purple100 transparent transparent transparent;
}

@keyframes spinnerSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}