.u-padding0 {
  padding: 0 !important;
}

.u-padding4 {
  padding: 4px !important;
}

.u-padding6 {
  padding: 6px !important;
}

.u-padding8 {
  padding: 8px !important;
}

.u-padding10 {
  padding: 10px !important;
}

.u-padding12 {
  padding: 12px !important;
}

.u-padding16 {
  padding: 16px !important;
}

.u-padding24 {
  padding: 24px !important;
}

.u-padding32 {
  padding: 32px !important;
}

.u-padding36 {
  padding: 36px !important;
}

.u-padding48 {
  padding: 48px !important;
}

.u-paddingHorizontal0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.u-paddingHorizontal6 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.u-paddingHorizontal8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.u-paddingHorizontal12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.u-paddingHorizontal16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.u-paddingHorizontal20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.u-paddingHorizontal24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.u-paddingHorizontal26 {
  padding-left: 26px !important;
  padding-right: 26px !important;
}

.u-paddingHorizontal32 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.u-paddingHorizontal36 {
  padding-left: 36px !important;
  padding-right: 36px !important;
}

.u-paddingHorizontal40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.u-paddingHorizontal48 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.u-paddingVertical0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.u-paddingVertical4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.u-paddingVertical5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.u-paddingVertical6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.u-paddingVertical8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.u-paddingVertical10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.u-paddingVertical12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.u-paddingVertical15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.u-paddingVertical16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.u-paddingVertical20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.u-paddingVertical23 {
  padding-top: 23px !important;
  padding-bottom: 23px !important;
}

.u-paddingVertical24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.u-paddingVertical25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.u-paddingVertical26 {
  padding-top: 26px !important;
  padding-bottom: 26px !important;
}

.u-paddingVertical28 {
  padding-top: 28px !important;
  padding-bottom: 28px !important;
}

.u-paddingVertical30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}

.u-paddingVertical32 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.u-paddingVertical36 {
  padding-top: 36px !important;
  padding-bottom: 36px !important;
}

.u-paddingLeft0 {
  padding-left: 0 !important;
}

.u-paddingLeft4 {
  padding-left: 4px !important;
}

.u-paddingLeft8 {
  padding-left: 8px !important;
}

.u-paddingLeft12 {
  padding-left: 12px !important;
}

.u-paddingLeft14 {
  padding-left: 14px !important;
}

.u-paddingLeft15 {
  padding-left: 15px !important;
}

.u-paddingLeft16 {
  padding-left: 16px !important;
}

.u-paddingLeft20 {
  padding-left: 20px !important;
}

.u-paddingLeft24 {
  padding-left: 24px !important;
}

.u-paddingLeft28 {
  padding-left: 28px !important;
}

.u-paddingLeft32 {
  padding-left: 32px !important;
}

.u-paddingLeft36 {
  padding-left: 36px !important;
}

.u-paddingLeft40 {
  padding-left: 40px !important;
}

.u-paddingLeft48 {
  padding-left: 48px !important;
}

.u-paddingLeft56 {
  padding-left: 56px !important;
}

.u-paddingLeft60 {
  padding-left: 60px !important;
}

.u-paddingLeft64 {
  padding-left: 64px !important;
}

.u-paddingLeft68 {
  padding-left: 68px !important;
}

.u-paddingLeft72 {
  padding-left: 72px !important;
}

.u-paddingLeft84 {
  padding-left: 84px !important;
}

.u-paddingLeft100 {
  padding-left: 100px !important;
}

.u-paddingLeft110 {
  padding-left: 110px !important;
}

.u-paddingLeft120 {
  padding-left: 120px !important;
}

.u-paddingLeft121 {
  padding-left: 121px !important;
}

.u-paddingLeft150 {
  padding-left: 150px !important;
}

.u-paddingLeft300 {
  padding-left: 300px !important;
}

.u-paddingTop0 {
  padding-top: 0 !important;
}

.u-paddingTop4 {
  padding-top: 4px !important;
}

.u-paddingTop8 {
  padding-top: 8px !important;
}

.u-paddingTop10 {
  padding-top: 10px !important;
}

.u-paddingTop11 {
  padding-top: 10px !important;
}

.u-paddingTop12 {
  padding-top: 12px !important;
}

.u-paddingTop13 {
  padding-top: 13px !important;
}

.u-paddingTop16 {
  padding-top: 16px !important;
}

.u-paddingTop18 {
  padding-top: 18px !important;
}

.u-paddingTop20 {
  padding-top: 20px !important;
}

.u-paddingTop24 {
  padding-top: 24px !important;
}

.u-paddingTop30 {
  padding-top: 30px !important;
}

.u-paddingTop32 {
  padding-top: 32px !important;
}

.u-paddingTop36 {
  padding-top: 36px !important;
}

.u-paddingTop40 {
  padding-top: 40px !important;
}

.u-paddingTop48 {
  padding-top: 48px !important;
}

.u-paddingTop56 {
  padding-top: 56px !important;
}

.u-paddingTop60 {
  padding-top: 60px !important;
}

.u-paddingTop64 {
  padding-top: 64px !important;
}

.u-paddingTop82 {
  padding-top: 82px !important;
}

.u-paddingTop86 {
  padding-top: 86px !important;
}

.u-paddingTop100 {
  padding-top: 100px !important;
}

.u-paddingTop200 {
  padding-top: 200px !important;
}

.u-paddingTopFull {
  padding-top: 100% !important;
}

.u-paddingRight0 {
  padding-right: 0 !important;
}

.u-paddingRight8 {
  padding-right: 8px !important;
}

.u-paddingRight12 {
  padding-right: 12px !important;
}

.u-paddingRight16 {
  padding-right: 16px !important;
}

.u-paddingRight20 {
  padding-right: 20px !important;
}

.u-paddingRight24 {
  padding-right: 24px !important;
}

.u-paddingRight28 {
  padding-right: 28px !important;
}

.u-paddingRight32 {
  padding-right: 32px !important;
}

.u-paddingRight36 {
  padding-right: 36px !important;
}

.u-paddingRight40 {
  padding-right: 40px !important;
}

.u-paddingRight48 {
  padding-right: 48px !important;
}

.u-paddingRight64 {
  padding-right: 64px !important;
}

.u-paddingRight80 {
  padding-right: 80px !important;
}

.u-paddingRight150 {
  padding-right: 150px !important;
}

.u-paddingRight320 {
  padding-right: 320px !important;
}

.u-paddingBottom0 {
  padding-bottom: 0 !important;
}

.u-paddingBottom8 {
  padding-bottom: 8px !important;
}

.u-paddingBottom9 {
  padding-bottom: 9px !important;
}

.u-paddingBottom11 {
  padding-bottom: 11px !important;
}

.u-paddingBottom12 {
  padding-bottom: 12px !important;
}

.u-paddingBottom16 {
  padding-bottom: 16px !important;
}

.u-paddingBottom20 {
  padding-bottom: 16px !important;
}

.u-paddingBottom20 {
  padding-bottom: 20px !important;
}

.u-paddingBottom24 {
  padding-bottom: 24px !important;
}

.u-paddingBottom30 {
  padding-bottom: 30px !important;
}

.u-paddingBottom32 {
  padding-bottom: 32px !important;
}

.u-paddingBottom40 {
  padding-bottom: 40px !important;
}

.u-paddingBottom48 {
  padding-bottom: 48px !important;
}

.u-paddingBottom50 {
  padding-bottom: 50px !important;
}

.u-paddingBottom64 {
  padding-bottom: 64px !important;
}

.u-paddingBottom80 {
  padding-bottom: 80px !important;
}

.u-paddingBottom92 {
  padding-bottom: 92px !important;
}

.u-paddingBottom100 {
  padding-bottom: 100px !important;
}

.u-paddingBottom45Percent {
  padding-bottom: 45%;
}

.u-paddingBottom2of3 {
  padding-bottom: 66.66666% !important;
}

.u-paddingBottom3of4 {
  padding-bottom: 75% !important;
}

.u-paddingBottom3of2 {
  padding-bottom: 150% !important;
}

.u-paddingBottom150Percent {
  padding-bottom: 150% !important;
}

.u-paddingBottomFull {
  padding-bottom: 100% !important;
}

@media screen and (min-width: 800px) {
  .u-md-padding0 {
    padding: 0 !important;
  }

  .u-md-padding10 {
    padding: 10px !important;
  }

  .u-md-padding16 {
    padding: 16px !important;
  }

  .u-md-padding36 {
    padding: 36px !important;
  }

  .u-md-paddingRight24 {
    padding-right: 24px !important;
  }

  .u-md-paddingRight32 {
    padding-right: 32px !important;
  }

  .u-md-paddingLeft60 {
    padding-left: 60px !important;
  }

  .u-md-paddingHorizontal36 {
    padding-left: 36px !important;
    padding-right: 36px !important;
  }

  .u-md-paddingHorizontal60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
}
